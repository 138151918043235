"use client";
import React, { useState } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { profilContent, profilHeader } from "@/constants/arrayServices";

type Props = {};

const service_profile = (props: Props) => {
  const [activeProfile, setactiveProfile] = useState(0);

  const handleActiveProfile = (index: number) => {
    setactiveProfile(index);
  };

  return (
    <section className="pb-24 flex flex-col justify-center items-center">
      {/* profil */}
      <div className="flex flex-row justify-center items-center w-full py-8 md:py-16">
        {profilHeader.map((profil, index) => {
          return (
            <article
              className="flex flex-col justify-center items-center py-4 md:gap-8 md:px-16 md:py-16 cursor-pointer"
              key={index}
              onClick={() => handleActiveProfile(index)}
            >
              <div className="w-[70px] h-[70px] md:w-[110px] md:h-[110px]">
                {profil.avatar}
              </div>
              <h3
                className={
                  index == activeProfile
                    ? "text-lg text-violet-600 font-bold text-left transition ease-in-out duration-300"
                    : "text-lg text-slate-500 font-bold text-left transition ease-in-out duration-300"
                }
              >
                {profil.name}
              </h3>
            </article>
          );
        })}
      </div>
      {/* content profil  */}
      {profilContent.map((profil, index) => {
        return (
          <div
            className={
              index == activeProfile
                ? "flex flex-col md:flex-row justify-center items-start w-full h-full"
                : "hidden"
            }
          >
            <aside className="w-full md:w-1/2 h-full px-12 flex flex-col justify-center items-start gap-4">
              <div className="w-[220px] h-[220px] mb-4">{profil.avatar}</div>
              <h3 className="text-2xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-left">
                {profil.name}
              </h3>
              <p className="text-muted-foreground md:text-lg/relaxed w-full text-left mb-16">
                {profil.content}
              </p>
            </aside>
            <article className="w-full md:w-1/2">
              <ul className="grid grid-cols2 sm:grid-cols-3 md:grid-cols-4 gap-4 xl:gap-[30px]">
                {profil.skillList.map((skill, index) => {
                  return (
                    <li key={index}>
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger className="w-full h-[150px] bg-[#E0DCD1] rounded-xl flex justify-center items-center group">
                            <div className="text-6xl group-hover:text-[#543310] transition-all duration-300">
                              {skill.icon}
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p className="capitalize">{skill.name}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </li>
                  );
                })}
              </ul>
            </article>
          </div>
        );
      })}
    </section>
  );
};

export default service_profile;
