"use client";
import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Logo_company } from "@/public";

export default function nav_1() {
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLinkClick = () => {
        setMenuOpen(false);
    };

    return (
        <header className="sticky top-0 bg-white z-50 w-full py-4 shadow">
            <nav className="relative md:mx-16 mx-8 flex justify-between items-center">
                <Link
                    className="text-3xl font-bold leading-none flex flex-row justify-center items-center"
                    href="/"
                    aria-label="Homepage"
                >
                    <Image
                        alt="logo CYDE"
                        className="h-16 w-16 mr-2"
                        src={Logo_company}
                    />
                    <span className="font-extrabold text-[#1CB0F6] hidden md:block">C</span>
                    <span className="font-extrabold text-[#FFA300] hidden md:block">Y</span>
                    <span className="font-extrabold text-[#74C00B] hidden md:block">D</span>
                    <span className="font-extrabold text-[#A35FE5] hidden md:block">E</span>
                </Link>
                <button
                    className="lg:hidden navbar-burger flex items-center text-black p-3"
                    aria-label="Toggle menu"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <svg
                        className="block h-6 w-6 fill-current"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Mobile menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                    </svg>
                </button>
                <ul
                    className={`lg:flex lg:items-center lg:w-auto lg:space-x-6 lg:ml-16 lg:gap-8 ${
                        menuOpen ? "" : "hidden"
                    }`}
                >
                    <li>
                        <Link
                            className="text-lg font-bold text-black hover:text-gray-600"
                            href="/products"
                            onClick={handleLinkClick}
                        >
                            Nos produits
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="text-lg font-bold text-black hover:text-gray-600"
                            href="/services"
                            onClick={handleLinkClick}
                        >
                            Nos services
                        </Link>
                    </li>
                </ul>
                <Link
                    className="hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 bg-black text-sm text-white hover:text-[#EBA41B] font-bold rounded-lg transition duration-200"
                    href="/contact"
                >
                    Nous contacter
                </Link>
            </nav>
            {menuOpen && (
                <div className="navbar-menu relative z-50">
                    <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                    <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
                        <div className="flex items-center mb-8">
                            <Link
                                className="mr-auto text-3xl font-bold leading-none flex flex-row justify-center items-center"
                                href="/"
                                aria-label="Homepage"
                                onClick={handleLinkClick}
                            >
                                <Image
                                    alt="logo CYDE"
                                    className="h-16 w-16"
                                    src={Logo_company}
                                />
                                <span className="font-extrabold text-[#1CB0F6] hidden md:block">C</span>
                                <span className="font-extrabold text-[#FFA300] hidden md:block">Y</span>
                                <span className="font-extrabold text-[#74C00B] hidden md:block">D</span>
                                <span className="font-extrabold text-[#A35FE5] hidden md:block">E</span>
                            </Link>
                            <button
                                className="navbar-close"
                                aria-label="Close menu"
                                onClick={() => setMenuOpen(false)}
                            >
                                <svg
                                    className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div>
                            <ul>
                                <li className="mb-1">
                                    <Link
                                        className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                                        href="/"
                                        onClick={handleLinkClick}
                                    >
                                        Accueil
                                    </Link>
                                </li>
                                <li className="mb-1">
                                    <Link
                                        className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                                        href="/products"
                                        onClick={handleLinkClick}
                                    >
                                        Nos produits
                                    </Link>
                                </li>
                                <li className="mb-1">
                                    <Link
                                        className="block p-4 text-sm font-semibold text-black hover:bg-[#EBA41B] rounded"
                                        href="/services"
                                        onClick={handleLinkClick}
                                    >
                                        Nos services
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-auto">
                            <div className="pt-6">
                                <Link
                                    className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-black text-white hover:text-[#EBA41B] rounded-lg"
                                    href="/contact"
                                    onClick={handleLinkClick}
                                >
                                    Nous contacter
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>
            )}
        </header>
    );
}
