import Hero_5_img from "./hero_5.jpg";
import Hero_6_img from "./hero_6.jpg";
import Hero_17_img from "./hero_17.png";
import Hero_18_img from "./hero_18.png";
import Hero_18_2_img from "./hero_18_2.png";
import Hero_19_img from "./hero_19.png";
import Logo_company from "./ico2.png";
import Playstore_logo from "./PlayStore.png";
import Youtube_logo_1 from "./youtube.png";
import Features_2_img from "./features_2.jpg";
import Bg_test from "./bg.png";

//
import IMSA_img_logo from "./imsa.png";
import SNCF_img_logo from "./sncf.avif";
import Bg_service from "./bg_service.png";
import Avatar_ayinde_img from "./avatar_ayinde.png";
import Avatar_nidal_img from "./avatar_nidal.png";
import Avatar_cyprien_img from "./avatar_cyprien.png";
import Hero_img from "./hero.webp";
import Landing_synergy_img from "./landing_synergy.png";
import Landing_innovation_img from "./landing_innovation.webp";
import Landing_excellence_img from "./landing_excellence.webp";
import Landing_transformation_img from "./landing_transformation.webp";
import Landing_ecoresponsabilite_img from "./landing_ecoresponsabilite.webp";


//product fr
import Product_fr_img from "./product_fr.png";
import Product_fr_detail_img from "./product_fr_detail.png";
import Product_fr_logo_img from "./product_logo_fr.png";
//product iovia
import Product_iovia_img from "./product_iovia.webp";
import Product_iovia_detail_img from "./product_iovia_detail.webp";
import Product_iovia_logo_img from "./product_logo_iovia.png";
//product eco
import Product_eco_logo_img from "./product_eco_logo.jpg";
//product co-innovation
import Product_coinnov_logo_img from "./product_coinnov_logo.jpg";
//product roadmap
import Product_roadmap_logo_img from "./product_roadmap_logo.png";

export {
    Hero_5_img,
    Hero_6_img,
    Hero_17_img,
    Hero_18_img,
    Hero_18_2_img,
    Hero_19_img,
    Logo_company,
    Playstore_logo,
    Youtube_logo_1,
    Features_2_img,
    IMSA_img_logo,
    SNCF_img_logo,
    Bg_test,
    Bg_service,
    Avatar_ayinde_img,
    Avatar_nidal_img,
    Avatar_cyprien_img,
    Hero_img,
    Landing_synergy_img,
    Landing_innovation_img,
    Landing_excellence_img,
    Landing_transformation_img,
    Landing_ecoresponsabilite_img,
    Product_fr_img,
    Product_fr_detail_img,
    Product_fr_logo_img,
    Product_iovia_img,
    Product_iovia_detail_img,
    Product_iovia_logo_img,
    Product_eco_logo_img,
    Product_coinnov_logo_img,
    Product_roadmap_logo_img,
};
